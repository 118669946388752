<template>
  <div class="home" ref="myRef">
    <div class="title">
      <img src="../assets/img/logo.png" alt="" />
    </div>
    <div class="content">
      <div class="management" v-for="(items, idx) in allList" :key="idx">
        <div class="management-title">
          <span class="lpArrow"></span>
          <span class="rpArrow"></span>
          <span class="lbArrow"></span>
          <span class="rbArrow"></span>
          <label>{{ items.title }}</label>
        </div>
        <div class="management-main">
          <div
            class="item"
            v-for="(item, index) in items.softwareList"
            :key="index"
          >
            <div class="itemTitle">{{ item.title }}</div>
            <span class="icon"
              ><img :src="require('@/assets/img/' + item.img + '.png')" alt=""
            /></span>
            <div class="info">
              <div>版本号：</div>
              <el-select
                v-model="item.currentVersion"
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in item.version"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="text" @click="download(item.currentVersion)">
              <span></span>
              <span></span>
              <span></span>
              <span></span> 下载
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
export default {
  name: "Home",
  setup() {
    const myRef = ref(null);
    const state = reactive({
      allList: [],
      currentVersion: "",
    });
    onMounted(() => {
      myRef.value.style.height =
        `${document.documentElement.clientHeight}` + "px"; //动态设置HTML元素高度
      state.allList = [
        {
          title: "未来战场",
          softwareList: [
            {
              title: "管理端",
              version: [
                {
                  label: "v1.2.149",
                  value:
                    "https://tool.stepx.tech/future/admin/1.2.149/UpdateLauncherOnLine.exe",
                },
              ],
              currentVersion:
                "https://tool.stepx.tech/future/admin/1.2.149/UpdateLauncherOnLine.exe",
              img: "UpdateLaunch",
            },
            {
              title: "客户端",
              version: [
                {
                  label: "v1.1.37",
                  value:
                    "https://tool.stepx.tech/future/client/1.1.37/EOBackpackClientInstall_1.1.37.exe",
                },
              ],
              currentVersion:
                "https://tool.stepx.tech/future/client/1.1.37/EOBackpackClientInstall_1.1.37.exe",
              img: "EOBackpackClientInstall",
            },
            {
              title: "mmap",
              version: [
                {
                  label: "v4006-13b",
                  value:
                    "https://step-software.oss-cn-beijing.aliyuncs.com/mmap/4006-13b/Step4006_ALL_13b_lite.exe",
                },
              ],
              currentVersion:
                "https://step-software.oss-cn-beijing.aliyuncs.com/mmap/4006-13b/Step4006_ALL_13b_lite.exe",
              img: "mmap",
            },
          ],
        },
        {
          title: "开发者",
          softwareList: [
            {
              title: "StepVrMocap",
              version: [
                {
                  label: "v4.26",
                  value:
                    "https://step-software.oss-cn-beijing.aliyuncs.com/develop/vrmocap/4.26/StepVrMocap.rar",
                },
                {
                  label: "v4.24",
                  value:
                    "https://step-software.oss-cn-beijing.aliyuncs.com/develop/vrmocap/4.24/StepVrMocap.rar",
                },
              ],
              currentVersion:
                "https://step-software.oss-cn-beijing.aliyuncs.com/develop/vrmocap/4.26/StepVrMocap.rar",
              img: "mission",
            },
            {
              title: "StepVrPlugin",
              version: [
                {
                  label: "v4.26",
                  value:
                    "https://step-software.oss-cn-beijing.aliyuncs.com/develop/vrplugin/4.26/StepVrPlugin.rar",
                },
                {
                  label: "v4.24",
                  value:
                    "https://step-software.oss-cn-beijing.aliyuncs.com/develop/vrplugin/4.24/StepVrPlugin.rar",
                },
              ],
              currentVersion:
                "https://step-software.oss-cn-beijing.aliyuncs.com/develop/vrplugin/4.26/StepVrPlugin.rar",
              img: "touch",
            },
          ],
        },
      ];
    });
    const download = (url) => {
      const a = document.createElement("a");
      a.href = url;
      a.click();
    };
    return {
      ...toRefs(state),
      myRef,
      download,
    };
  },
};
</script>
<style lang="scss">
.home {
  width: 100%;
  height: auto;
  background: #050a32;
  padding: 20px 0 0 0;
  box-sizing: border-box;
  .title {
    width: 100%;
    height: 100px;
    overflow: hidden;
    background: url("../assets/img/title.png") no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    font-size: 46px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .content {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 15px 30px 0 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .management {
      width: 50%;
      max-width: 50%;
      height: auto;
      overflow: hidden;
      background: rgba(6, 7, 107, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-title {
        height: 60px;
        margin-bottom: 40px;
        background: #05081f;
        position: relative;
        width: fit-content;
        label {
          font-size: 22px;
          color: #00bcfd;
          font-weight: bold;
          line-height: 60px;
          padding: 0 80px;
          letter-spacing: 5px;
        }
        span {
          position: absolute;
          width: 10px;
          height: 10px;
        }
        .lpArrow {
          border-top: 3px solid #00bcfd;
          border-left: 3px solid #00bcfd;
        }
        .rpArrow {
          right: 0;
          border-top: 3px solid #00bcfd;
          border-right: 3px solid #00bcfd;
        }
        .lbArrow {
          bottom: 0;
          border-left: 3px solid #00bcfd;
          border-bottom: 3px solid #00bcfd;
        }
        .rbArrow {
          right: 0;
          bottom: 0;
          border-right: 3px solid #00bcfd;
          border-bottom: 3px solid #00bcfd;
        }
      }
      &-main {
        width: 100%;
        height: auto;
        overflow: hidden;
        box-sizing: border-box;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
        .item {
          width: 28%;
          height: auto;
          padding: 40px 0;
          float: left;
          // margin: 0 20px;
          background: url("../assets/img/item.png") no-repeat center center;
          background-size: 100% 100%;
          float: left;
          margin-right: 5%;
          margin-bottom: 40px;
          .icon {
            width: 100%;
            height: 100px;
            max-height: 100px;
            display: inline-block;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            -ms-transition: all 0.3s linear;
            text-align: center;
            img {
              // width: 100%;
              height: 100%;
            }
          }
          .text {
            width: 120px;
            height: 40px;
            line-height: 35px;
            color: #0be3f5;
            font-family: "微软雅黑";
            font-weight: bold;
            border: 2px solid rgba(200, 155, 155, 0.3);
            margin: 0 auto;
            box-sizing: border-box;
            -ms-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            transition: all 0.3s linear;
            position: relative;
            text-align: center;
            border-radius: 8px;
            letter-spacing: 8px;
            font-size: 16px;
            overflow: hidden;
          }
          .text:hover {
            border-radius: 8px;
            background: rgba(3, 233, 244, 1);
            color: #fff;
            box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
              0 0 100px #03e9f4;
            background-position: 20px center;
          }
          .text span {
            position: absolute;
            display: block;
          }

          .text span:nth-child(1) {
            top: 0;
            left: -100%;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, transparent, #03e9f4);
            animation: btnAnim1 1s linear infinite;
          }

          @keyframes btnAnim1 {
            0% {
              left: -100%;
            }
            50%,
            100% {
              left: 100%;
            }
          }

          .text span:nth-child(2) {
            top: -100%;
            right: 0;
            width: 2px;
            height: 100%;
            background: linear-gradient(180deg, transparent, #03e9f4);
            animation: btnAnim2 1s linear infinite;
            animation-delay: 0.25s;
          }

          @keyframes btnAnim2 {
            0% {
              top: -100%;
            }
            50%,
            100% {
              top: 100%;
            }
          }

          .text span:nth-child(3) {
            bottom: 0;
            right: -100%;
            width: 100%;
            height: 2px;
            background: linear-gradient(270deg, transparent, #03e9f4);
            animation: btnAnim3 1s linear infinite;
            animation-delay: 0.5s;
          }

          @keyframes btnAnim3 {
            0% {
              right: -100%;
            }
            50%,
            100% {
              right: 100%;
            }
          }

          .text span:nth-child(4) {
            bottom: -100%;
            left: 0;
            width: 2px;
            height: 100%;
            background: linear-gradient(360deg, transparent, #03e9f4);
            animation: btnAnim4 1s linear infinite;
            animation-delay: 0.75s;
          }

          @keyframes btnAnim4 {
            0% {
              bottom: -100%;
            }
            50%,
            100% {
              bottom: 100%;
            }
          }
        }
        .item:nth-child(5n) {
          margin-right: 0;
        }
        .item:hover .icon {
          // transform: rotate(360deg) scale(1.2);
          // -ms-transform: rotate(360deg) scale(1.2);
          // -webkit-transform: rotate(360deg) scale(1.2);
          // -moz-transform: rotate(360deg) scale(1.2);
        }
        .info {
          width: 100%;
          height: auto;
          font-size: 14px;
          color: #fff;
          text-align: center;
          margin: 30px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          box-sizing: border-box;
          .el-select {
            width: 60%;
            .el-input__inner {
              background: transparent !important;
              border: 1px solid #00bcfd;
              color: #00bcfd;
            }
            .el-input__icon {
              color: #00bcfd;
            }
          }
        }
        .itemTitle {
          font-size: 14px;
          color: #fff;
          text-align: center;
          margin: 10px 0;
        }
      }
    }
  }
}
.el-select__popper {
  background: #00bcfd !important;
  border: 1px solid #00bcfd !important;

  .el-select-dropdown__item {
    color: #fff !important;
  }
}
.el-popper__arrow::before {
  background: #00bcfd !important;
  border: 1px solid #00bcfd !important;
}
.el-select-dropdown__item:hover {
  // background: ;
  color: #00bcfd !important;
}
</style>
